import { DatePicker } from 'antd';
import clsx from 'clsx';
import * as eva from 'eva-icons';
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import React, { useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS, DEFAULTDATEFORMAT } from '../../common/constants';
import IndividualReport from './IndividualReport';
import ProjectReport from './ProjectReport';

const MyDatePicker = DatePicker.generatePicker(momentGenerateConfig);
const { RangePicker } = MyDatePicker;
const CustomRangePicker = ({
  setDateSelected,
  setFromDate,
  setToDate,
  className,
}) => {
  const disabledDate = (current) => {
    // Can not select future date
    return current && current > moment()?.endOf('day');
  };
  return (
    <RangePicker
      className={clsx('custom-date-picker', className)}
      disabledDate={disabledDate}
      separator="-"
      popupStyle={{ top: 30 }}
      suffixIcon={<i data-eva="calendar-outline" />}
      format={DEFAULTDATEFORMAT}
      popupClassName="custom-date-picker-panel"
      renderExtraFooter={() => (
        <div className="text-danger">
          All data is based on Greenwich Mean Time (GMT)
        </div>
      )}
      placeholder={['Start Date', 'End Date']}
      defaultValue={[moment().startOf('month'), moment()]}
      presets={[
        {
          label: 'Yesterday',
          value: [moment().subtract(1, 'days'), moment()],
        },
        {
          label: 'Last 8 Days',
          value: [moment().subtract(7, 'days'), moment()],
        },
        {
          label: 'Last 31 Days',
          value: [moment().subtract(30, 'days'), moment()],
        },
        {
          label: 'Last 3 Months',
          value: [moment().subtract(3, 'months'), moment()],
        },
      ]}
      onChange={(e, dates) => {
        if (e) {
          setDateSelected(true);
        } else {
          setDateSelected(false);
        }
        setFromDate(dates[0]);
        setToDate(dates[1]);
      }}
    />
  );
};
const Reports = () => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const [dateSelected, setDateSelected] = useState(true);
  const [fromDate, setFromDate] = useState(
    moment().startOf('month').format(DEFAULTDATEFORMAT),
  );
  const [toDate, setToDate] = useState(moment().format(DEFAULTDATEFORMAT));
  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="reports">
        {!isDesktopViewport && (
          <div className="profile-header d-flex justify-between">
            <div className="page-header text-primary mr-10">Reports</div>
            <CustomRangePicker
              className="blinking-border"
              setDateSelected={setDateSelected}
              setFromDate={setFromDate}
              setToDate={setToDate}
            />
          </div>
        )}
        {isDesktopViewport && (
          <>
            <div className="reports-header d-flex align-center justify-between">
              <div className="d-flex align-center">
                <i data-eva="file-text-outline" />
                <h1>Reports</h1>
              </div>
              <CustomRangePicker
                className="blinking-border"
                setDateSelected={setDateSelected}
                setFromDate={setFromDate}
                setToDate={setToDate}
              />
            </div>
          </>
        )}

        <div className="reports-content">
          <ProjectReport
            dateSelected={dateSelected}
            fromDate={fromDate}
            toDate={toDate}
          />
          <IndividualReport
            dateSelected={dateSelected}
            fromDate={fromDate}
            toDate={toDate}
          />
        </div>
      </div>
    </>
  );
};

export default Reports;
