import { useLazyQuery, useMutation } from '@apollo/client';
import { DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, rectSortingStrategy, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Col, Row, Table, Tooltip } from 'antd';
import clsx from 'clsx';
import { map, sortBy } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../AppContext';
import {
  AddButton,
  DeleteButton,
  DragIcon,
  EditButton,
  RightIcon,
  WrongIcon,
} from '../../../../../assets/svg';
import {
  BREAKPOINTS,
  DEFAULT_PAGE_SIZE,
  GA_EVENT,
  GA_LABEL,
} from '../../../../../common/constants';
import { Event } from '../../../../../common/trackEvents';
import useRouter from '../../../../../common/useRouter';
import CommonCard from '../../../../../components/CommonCard';
import EllipsisText from '../../../../../components/EllipsisText';
import InfiniteScrollHandler from '../../../../../components/InfiniteScrollHandler';
import Portal from '../../../../../components/Portal';
import {
  DELETE_STAGE_ITEM,
  REORDER_STAGE_ITEM,
} from '../../../graphql/Mutation';
import { GET_STAGE_ITEM_LIST } from '../../../graphql/Queries';
import DeleteModalStage from '../DeleteModalStage';
import AddModal from './AddModal';

const RowContext = React.createContext({});

const DraggableCard = ({ id, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'grab',
    opacity: isDragging ? 0.4 : 1,
  };
  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <CommonCard className="stage-card">{children}</CommonCard>
    </div>
  );
};

const Rows = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    // eslint-disable-next-line react/destructuring-assignment
    id: props['data-row-key'],
  });
  const style = {
    // eslint-disable-next-line react/destructuring-assignment
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
        position: 'relative',
        zIndex: 9999,
      }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners],
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};


const StageItemTable = ({ stageId }) => {
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const initialStageItemFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'order', order: 'ASC' },
  };
  const initialPaginationValue = {
    total: 0,
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };
  const DragHandle = () => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
      <DragIcon style={{
        cursor: 'move',
      }}
        ref={setActivatorNodeRef}
        {...listeners}
      />
    );
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showModal, setShowModal] = useState(false);
  const [stageItemData, setStageItemData] = useState();
  const [deleteStageItemModal, setDeleteStageItemModal] = useState(false);
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [mutationId, setMutationId] = useState('');
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [stageItemListData, setStageItemListData] = useState([]);
  const [stageItemFilter, setStageItemFilter] = useState({
    ...initialStageItemFilter,
    eqcTypeStageId: parseInt(stageId, 10),
  });
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(
    mouseSensor,
    touchSensor,
    keyboardSensor,
  );
  const { params: { eqcTypeId } } = useRouter();
  const [fetchStageItemData, { loading, refetch }] = useLazyQuery(
    GET_STAGE_ITEM_LIST,
    {
      variables: { filter: stageItemFilter },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.eqcTypeStageItemList?.data;
        const pagination = {
          ...paginationProp,
          total: res?.eqcTypeStageItemList?.total,
        };
        setPaginationProp(pagination);
        if (scrollFlag) {
          const datalist = [...stageItemListData, ...data];
          setStageItemListData(sortBy(datalist, ['order']));
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setStageItemListData(sortBy(datalist, ['order']));
        }
        setHasMore(!!data?.length);
      },
      onError() { },
    },
  );
  const handleRefetchAfterDelete = () => {
    const newSkip =
      stageItemListData?.length === 1
        ? Math.max(0, stageItemFilter?.skip - paginationProp?.pageSize)
        : stageItemFilter?.skip;
    setStageItemFilter({
      ...stageItemFilter,
      skip: newSkip,
    });
    fetchStageItemData({
      variables: {
        filter: {
          ...stageItemFilter,
          skip: newSkip,
        },
      },
    });
  };
  const [deleteStageItem] = useMutation(DELETE_STAGE_ITEM, {
    refetch,
    onError() { },
    onCompleted() {
      Event(GA_EVENT.DELETE_GLOBAL_CHECKLIST_STAGE_ITEM, {
        label: GA_LABEL.DELETE_GLOBAL_CHECKLIST_STAGE_ITEM,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        checklist_id: eqcTypeId,
        stage_id: stageId,
        stage_item_id: mutationId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
      });
      setDeleteStageItemModal(false);
      handleRefetchAfterDelete();
    },
  });
  const [reorderStageItem, { loading: reorderLoading }] = useMutation(
    REORDER_STAGE_ITEM,
    {
      onCompleted() {
        Event(GA_EVENT.REORDER_GLOBAL_CHECKLIST_STAGE_ITEM, {
          label: GA_LABEL.REORDER_GLOBAL_CHECKLIST_STAGE_ITEM,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          checklist_id: eqcTypeId,
          stage_id: stageId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
      },
      onError() {
      },
    },
  );
  useEffect(() => {
    if (stageId) {
      setStageItemFilter({
        ...initialStageItemFilter,
        eqcTypeStageId: parseInt(stageId, 10),
      });
      setPaginationProp(initialPaginationValue);
      fetchStageItemData({
        variables: {
          filter: {
            ...initialStageItemFilter,
            eqcTypeStageId: parseInt(stageId, 10),
          },
        },
      });
    }
  }, [stageId]);
  const refetchStageDetails = () => {
    fetchStageItemData({
      variables: {
        filter: {
          ...stageItemFilter,
          eqcTypeStageId: parseInt(stageId, 10),
        },
      },
    });
  };
  const handleAddEdit = (record) => {
    if (record) {
      setStageItemData(record);
    } else {
      setStageItemData();
    }
    setShowModal(true);
  };
  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setStageItemFilter({
      ...stageItemFilter,
      skip,
      limit: pagination.pageSize,
    });
    setPaginationProp({ ...paginationProp, ...pagination });
    fetchStageItemData({
      variables: {
        filter: {
          ...stageItemFilter,
          skip,
          limit: pagination.pageSize,
        },
      },
    });
  };
  const getActionButton = (record) => {
    return (
      <div className={clsx(isDesktopViewport && 'd-flex')}>
        <Tooltip title="Edit">
          <Button
            shape="round"
            icon={<EditButton />}
            className="edit-button pointer b-0"
            onClick={() => {
              handleAddEdit(record);
            }}
          />
        </Tooltip>
        <Tooltip title="Delete">
          <Button
            className="delete-button pointer b-0"
            shape="round"
            icon={<DeleteButton />}
            onClick={() => {
              setTitle('Stage Item');
              setName(record?.title);
              setMutationId(record?.id);
              setDeleteStageItemModal(true);
            }}
          />
        </Tooltip>
      </div>
    );
  };
  const columns = [
    {
      width: '5%',
      className: 'drag-visible',
      render: () => (
        <Tooltip title="Change order">
          <div>
            <DragHandle />
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'ID',
      key: 'id',
      render: (text, record, index) => {
        return <div>{stageItemFilter?.skip + index + 1}</div>;
      },
    },
    {
      title: 'Checkpoint',
      dataIndex: 'title',
      key: 'title',
      render: (record) => (
        <div>
          <EllipsisText text={record} />
        </div>
      ),
    },
    {
      title: 'INPUT',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'PHOTOS',
      key: 'photo',
      render: (record) => {
        if (record?.photo === true) {
          return <RightIcon />;
        }
        return <WrongIcon className="wrong-icon" />;
      },
    },
    {
      title: 'REMARKS',
      key: 'remark',
      render: (record) => {
        if (record?.remark === true) {
          return <RightIcon />;
        }
        return <WrongIcon className="wrong-icon" />;
      },
    },
    {
      key: 'action',
      render: (record) => getActionButton(record),
    },
  ];

  const DraggableBodyRow = async ({ over, active }) => {
    if (!active || !over || active?.id === over?.id) return;
    const overIndex = over?.data?.current?.sortable?.index
    const activeIndex = active?.data?.current?.sortable?.index
    const data = [...stageItemListData]
    setStageItemListData(arrayMove(data, activeIndex, overIndex))
    try {
      const { errors } = await reorderStageItem({
        variables: {
          data: {
            eqcTypeStageItemIds: [
              active?.id,
              over?.id,
            ],
            eqcTypeStageId: parseInt(stageId, 10),
          },
        },
      })
      if (errors) {
        throw errors
      }
    } catch (error) {
      setStageItemListData(data)
    }
  };

  const handleRefetch = () => {
    fetchStageItemData({
      variables: {
        filter: {
          ...stageItemFilter,
          skip: stageItemListData?.length,
          limit: DEFAULT_PAGE_SIZE,
        },
      },
    });
  };

  return (
    <>
      {showModal && (
        <AddModal
          showModal={showModal}
          setShowModal={setShowModal}
          stageData={stageItemData}
          isUpdate={!!stageItemData}
          setStageData={setStageItemData}
          refetchStageDataWithInitialValues={refetchStageDetails}
          refetchStageData={refetchStageDetails}
          stageId={stageId}
        />
      )}

      {deleteStageItemModal && (
        <DeleteModalStage
          showModal={deleteStageItemModal}
          setShowModal={setDeleteStageItemModal}
          title={title}
          name={name}
          deleteStage={deleteStageItem}
          mutationId={mutationId}
          setMutationId={setMutationId}
        />
      )}
      <Portal portalId="stageitem-add-btn">
        <Button
          shape="round"
          type="primary"
          id="add-btn"
          icon={<AddButton />}
          className="add-button"
          onClick={() => {
            handleAddEdit();
          }}
        >
          {isDesktopViewport && 'Add Item'}
        </Button>
      </Portal>
      <DndContext onDragEnd={DraggableBodyRow} sensors={sensors} modifiers={[restrictToVerticalAxis]}>
        {isDesktopViewport ? (

          <SortableContext items={stageItemListData.map((i) => i.id)} strategy={verticalListSortingStrategy}>
            <Table
              components={{ body: { row: Rows } }}
              columns={columns}
              dataSource={stageItemListData || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowKey="id"
              loading={loading || reorderLoading}
            />
          </SortableContext>

        ) : (
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            loading={loading || reorderLoading}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            wrapperClassName="stage-item-scroll-wrapper"
            dataLength={stageItemListData?.length}
            skeletonRows={columns?.length - 4}
          >
            <SortableContext items={stageItemListData} strategy={rectSortingStrategy}>
              {map(stageItemListData, (item, index) => {
                return (
                  <DraggableCard className="stage-card" id={item?.id} key={item?.id}>
                    <div className="common-card d-flex">
                      <div className="mr-5 fw-medium">
                        <DragHandle />
                      </div>
                      <div>
                        <div className="card-header fw-medium">
                          <Row wrap={false}>
                            <Col flex="auto">
                              <span className="mr-5">{index + 1}.</span>
                              {item?.title}
                            </Col>
                          </Row>
                        </div>
                        <div className="card-content text-secondary">
                          <br />
                          <div className="mb-15">
                            <span className="fw-medium mr-5">Input:</span>
                            {item?.type}
                          </div>
                          <div className="mb-15 d-flex align-center">
                            <span className="fw-medium mr-5">Photos:</span>
                            {item?.photo === true ? (
                              <RightIcon />
                            ) : (
                              <WrongIcon className="wrong-icon" />
                            )}
                          </div>
                          <div className=" d-flex align-center">
                            <span className="fw-medium mr-5">Remarks:</span>
                            {item?.remark === true ? (
                              <RightIcon />
                            ) : (
                              <WrongIcon className="wrong-icon" />
                            )}
                          </div>
                        </div>
                      </div>
                      <span className="position-absolute stage-item-action-btn">
                        {getActionButton(item)}
                      </span>
                    </div>
                  </DraggableCard>
                );
              })}
            </SortableContext>

          </InfiniteScrollHandler>

        )}
      </DndContext>
    </>
  );
};

export default StageItemTable;
