import { Avatar, Menu } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import alternateProfile from '../../../assets/images/alternateProfile.png';
import {
  ALLOWED_ACTION_KEYS,
  MODULES,
  ROUTES,
} from '../../../common/constants';
import useRouter from '../../../common/useRouter';
import CanPerform from '../../../components/CanPerform';
import NotificationModal from '../../../modules/notification/NotificationModal';

const UserProfile = ({ userData }) => {
  const { location: { pathname } } = useRouter()
  const [showModal, setShowModal] = useState(false);

  const profileItem = [
    {
      label: 'Account',
      key: 'SubMenu',
      icon: <Avatar
        src={userData?.getLoggedInUser?.profileImage || alternateProfile}
        className="profileImage gx-pointer mr-5 p-0"
        alt="Avatar"
      />,
      children: [
        {
          label: (
            <Link to={ROUTES.PROFILE} >
              {MODULES.PROFILE}
            </Link>
          ),
          key: ROUTES.PROFILE,
        },
        ...(CanPerform({
          action: ALLOWED_ACTION_KEYS.NOTIFICATION_SETTING,
        }) ?
          [{
            label: (
              <div onClick={() => setShowModal(true)}>
                {MODULES.NOTIFICATION}
              </div>
            ),
            key: ROUTES.NOTIFICATION,
          }] : []),
        {
          label: (
            <Link to={ROUTES.CHANGE} >
              {MODULES.CHANGE}
            </Link>
          ),
          key: ROUTES.CHANGE,
        },
        {
          label: (
            <Link to={ROUTES.LOGOUT} >
              {MODULES.LOGOUT}
            </Link>
          ),
          key: ROUTES.LOGOUT,
        },
      ],
    },
  ];

  return (
    <div className="gx-avatar-row pointer header-right p-0">
      <div className="header-right-menu" />
      <Menu
        selectedKeys={[`/${pathname?.split('/')[1]}`]}
        mode="horizontal"
        items={profileItem}
      />
      {showModal && (
        <NotificationModal showModal={showModal} setShowModal={setShowModal} />
      )}
    </div>
  );
};

export default UserProfile;
