import { useLazyQuery, useMutation } from '@apollo/client';
import { closestCenter, DndContext, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, rectSortingStrategy, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Col, Dropdown, Row, Table, Tooltip } from 'antd';
import clsx from 'clsx';
import { includes, map, sortBy } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../../AppContext';
import {
  AddButton,
  DeleteButton,
  DragIcon,
  EditButton,
  KebabMenu,
  RightIcon,
  WrongIcon,
} from '../../../../../../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  DEFAULT_PAGE_SIZE,
  GA_EVENT,
  GA_LABEL,
  PROJECT_CHECKLIST_STATUS,
} from '../../../../../../../common/constants';
import { Event } from '../../../../../../../common/trackEvents';
import useRouter from '../../../../../../../common/useRouter';
import CanPerform from '../../../../../../../components/CanPerform';
import CommonCard from '../../../../../../../components/CommonCard';
import EllipsisText from '../../../../../../../components/EllipsisText';
import InfiniteScrollHandler from '../../../../../../../components/InfiniteScrollHandler';
import Portal from '../../../../../../../components/Portal';
import {
  DELETE_PROJECT_RFI_POINT,
  REORDER_PROJECT_RFI_POINT,
  UPDATE_PROJECT_RFI_POINT,
} from '../../../../../graphql/Mutation';
import { PROJECT_RFI_POINTS_LIST } from '../../../../../graphql/Queries';
import DeleteModalStage from '../DeleteModalStage';
import AddEditModal from './AddEditModal';

const RowContext = React.createContext({});
const DraggableCard = ({ id, children }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'grab',
    opacity: isDragging ? 0.4 : 1,
  };
  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <CommonCard className="stage-card">{children}</CommonCard>
    </div>
  );
};
const Rows = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    // eslint-disable-next-line react/destructuring-assignment
    id: props['data-row-key'],
  });
  const style = {
    // eslint-disable-next-line react/destructuring-assignment
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
        position: 'relative',
        zIndex: 9999,
      }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners],
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};
const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <DragIcon style={{
      cursor: 'move',
    }}
      onClick={(e) => e.preventDefault()}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};


const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: DEFAULT_PAGE_SIZE,
};

const RfiPointsTable = ({ RFIData, isEditable }) => {
  const initialRfiPointFilter = {
    skip: 0,
    limit: 10,
    projectRFIId: RFIData?.id,
  };
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [showModal, setShowModal] = useState(false);
  const [deleteRfiPointModal, setDeleteRfiPointModal] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [rfiPointFilter, setRfiPointFilter] = useState(initialRfiPointFilter);
  const [rfiPointListData, setRfiPointListData] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(
    mouseSensor,
    touchSensor,
    keyboardSensor,
  );

  const { params: { projectId } } = useRouter();
  const [getProjectRFIPointList, { loading }] = useLazyQuery(
    PROJECT_RFI_POINTS_LIST,
    {
      variables: { filter: rfiPointFilter },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.projectRFIPointList?.data;
        const pagination = {
          ...paginationProp,
          total: res?.projectRFIPointList?.total,
        };
        if (scrollFlag) {
          const datalist = [...rfiPointListData, ...data];
          setRfiPointListData(sortBy(datalist, ['order']));
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setRfiPointListData(sortBy(datalist, ['order']));
        }
        setHasMore(!!data?.length);
        setPaginationProp(pagination);
      },
      onError() { },
    },
  );
  useEffect(() => {
    if (RFIData?.id) {
      setRfiPointFilter({
        ...initialRfiPointFilter,
        projectRFIId: RFIData?.id,
      });
      setPaginationProp(initialPaginationValue);
      getProjectRFIPointList({
        variables: {
          filter: {
            ...initialRfiPointFilter,
            projectRFIId: RFIData?.id,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RFIData?.id]);

  const handleRefetchAfterDelete = () => {
    const newSkip =
      rfiPointListData?.length === 1
        ? Math.max(0, rfiPointFilter?.skip - paginationProp?.pageSize)
        : rfiPointFilter?.skip;
    setRfiPointFilter({
      ...rfiPointFilter,
      skip: newSkip,
    });
    setSelectedData();
    getProjectRFIPointList({
      variables: {
        filter: {
          ...rfiPointFilter,
          projectRFIId: RFIData?.id,
          skip: newSkip,
        },
      },
    });
  };
  const [updateProjectRFIPoint] = useMutation(UPDATE_PROJECT_RFI_POINT, {
    onError() { },
    onCompleted() {
      Event(GA_EVENT.ADD_PROJECT_RFI_POINT, {
        label: GA_LABEL.ADD_PROJECT_RFI_POINT,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
      });
      setDeleteRfiPointModal(false);
      handleRefetchAfterDelete();
    },
  });

  const [deleteStageItem] = useMutation(DELETE_PROJECT_RFI_POINT, {
    onError() { },
    onCompleted() {
      Event(GA_EVENT.DELETE_PROJECT_RFI_POINT, {
        label: GA_LABEL.DELETE_PROJECT_RFI_POINT,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
      });
      setDeleteRfiPointModal(false);
      handleRefetchAfterDelete();
    },
  });

  const [reOrderProjectRFIPoint, { loading: reorderLoading }] = useMutation(
    REORDER_PROJECT_RFI_POINT,
    {
      onError() { },
      onCompleted() {
        Event(GA_EVENT.REORDER_PROJECT_RFI_POINT, {
          label: GA_LABEL.REORDER_PROJECT_RFI_POINT,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName,
        });
        handleRefetchAfterDelete();
      },
    },
  );

  const handleAddEdit = (record) => {
    if (record) {
      setSelectedData(record);
    } else {
      setSelectedData();
    }
    setShowModal(true);
  };

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setRfiPointFilter({
      ...rfiPointFilter,
      projectRFIId: RFIData?.id,
      skip,
      limit: pagination.pageSize,
    });
    setPaginationProp({ ...paginationProp, ...pagination });
    getProjectRFIPointList({
      variables: {
        filter: {
          ...rfiPointFilter,
          projectRFIId: RFIData?.id,
          skip,
          limit: pagination.pageSize,
        },
      },
    });
  };
  const handleActiveAndDelete = (record) => {
    setDeleteRfiPointModal(true);
    setSelectedData(record);
  };

  const getActionButtons = (record) => {
    return (
      <CanPerform
        action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS}
        type={ALLOWED_ACTION_TYPE.BOTH}
      >
        {isEditable ? (
          <div className={clsx(isDesktopViewport && 'd-flex', 'action-icons')}>
            <Tooltip
              title={
                (RFIData?.status === PROJECT_CHECKLIST_STATUS?.UNPUBLISHED ||
                  !RFIData?.project?.isActive) &&
                'Edit'
              }
            >
              <Button
                shape="round"
                icon={<EditButton />}
                className={clsx(
                  'edit-button pointer b-0',
                  (RFIData?.status === PROJECT_CHECKLIST_STATUS?.PUBLISHED ||
                    RFIData?.status === PROJECT_CHECKLIST_STATUS?.ARCHIVED ||
                    !RFIData?.project?.isActive) &&
                  'disabled-button',
                )}
                disabled={!record?.isActive}
                onClick={() => {
                  handleAddEdit(record);
                }}
              />
            </Tooltip>
            {!includes(
              [
                PROJECT_CHECKLIST_STATUS.PUBLISHED,
                PROJECT_CHECKLIST_STATUS.ARCHIVED,
              ],
              RFIData?.projectRfi?.status,
            ) && (
                <Tooltip
                  title={
                    (RFIData?.status === PROJECT_CHECKLIST_STATUS?.UNPUBLISHED ||
                      !RFIData?.project?.isActive) &&
                    'Delete'
                  }
                >
                  <Button
                    className={clsx(
                      'delete-button pointer b-0',
                      (RFIData?.status === PROJECT_CHECKLIST_STATUS?.PUBLISHED ||
                        RFIData?.status === PROJECT_CHECKLIST_STATUS?.ARCHIVED ||
                        !RFIData?.project?.isActive) &&
                      'disabled-button',
                    )}
                    shape="round"
                    icon={<DeleteButton />}
                    disabled={
                      RFIData?.status === PROJECT_CHECKLIST_STATUS?.PUBLISHED ||
                      RFIData?.status === PROJECT_CHECKLIST_STATUS?.ARCHIVED ||
                      !RFIData?.project?.isActive
                    }
                    onClick={() => {
                      handleActiveAndDelete(record);
                    }}
                  />
                </Tooltip>
              )}
            {includes(
              [
                PROJECT_CHECKLIST_STATUS.PUBLISHED,
                PROJECT_CHECKLIST_STATUS.ARCHIVED,
              ],
              RFIData?.projectRfi?.status,
            ) && (
                <Dropdown
                  menu={{
                    items: [{
                      key: record?.isActive ? 'deactivate' : 'activate',
                      label: <span onClick={() => handleActiveAndDelete(record)}>{record?.isActive ? 'Deactivate' : 'Activate'}</span>,
                    }],
                  }}
                  getPopupContainer={(triggerNode) => triggerNode?.parentNode}
                  trigger={['click']}
                >
                  <Button
                    shape="round"
                    className="kebab-icon"
                    icon={<KebabMenu />}
                    onClick={(e) => e.stopPropagation()}
                  />
                </Dropdown>
              )}
          </div>
        ) : (
          <div className="d-flex justify-end">
            <div
              className="pointer text-underline mr-10"
              onClick={() => {
                handleAddEdit(record);
              }}
            >
              View
            </div>
          </div>
        )}
      </CanPerform>
    );
  };
  const columns = [
    ...(isEditable
      ? [
        {
          width: '5%',
          className: 'drag-visible',
          render: () =>
            RFIData?.project?.isActive && (
              <Tooltip title="Change order">
                <div>
                  <DragHandle />
                </div>
              </Tooltip>
            ),
        },
      ]
      : []),
    {
      title: 'ID',
      key: 'id',
      render: (text, record, index) => {
        return <div>{rfiPointFilter?.skip + index + 1}</div>;
      },
    },
    {
      title: 'CHECKPOINT',
      dataIndex: 'title',
      key: 'title',
      render: (record) => (
        <div>
          <EllipsisText text={record} />
        </div>
      ),
    },
    {
      title: 'INPUT',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'PHOTOS',
      key: 'photo',
      render: (record) => {
        if (record?.photo === true) {
          return <RightIcon />;
        }
        return <WrongIcon className="wrong-icon" />;
      },
    },
    {
      title: 'REMARKS',
      key: 'remark',
      render: (record) => {
        if (record?.remark === true) {
          return <RightIcon />;
        }
        return <WrongIcon className="wrong-icon" />;
      },
    },
    {
      key: 'action',
      render: (record) => getActionButtons(record),
    },
  ];

  const DraggableBodyRow = async ({ over, active }) => {
    if (!active || !over || active?.id === over?.id) return;
    const overIndex = over?.data?.current?.sortable?.index
    const activeIndex = active?.data?.current?.sortable?.index
    const data = [...rfiPointListData]
    setRfiPointListData(arrayMove(data, activeIndex, overIndex))
    try {
      const { errors } = await reOrderProjectRFIPoint({
        variables: {
          data: {
            projectRFIPointIds: [
              active?.id,
              over?.id,
            ],
            projectRFIId: parseInt(RFIData?.id, 10),
          },
        },
      })
      if (errors) {
        throw errors
      }
    } catch (error) {
      setRfiPointListData(data)
    }
  };

  return (
    <div id="project-stage-item-module">
      {showModal && (
        <AddEditModal
          showModal={showModal}
          setShowModal={setShowModal}
          selectedData={selectedData}
          isUpdate={!!selectedData}
          refetchStageDataWithInitialValues={handleRefetchAfterDelete}
          RFIData={RFIData}
          isDisabled={
            RFIData?.status === PROJECT_CHECKLIST_STATUS?.PUBLISHED ||
            RFIData?.status === PROJECT_CHECKLIST_STATUS?.ARCHIVED ||
            !RFIData?.project?.isActive
          }
          isEditable={isEditable}
        />
      )}
      {deleteRfiPointModal && (
        <DeleteModalStage
          showModal={deleteRfiPointModal}
          setShowModal={setDeleteRfiPointModal}
          data={selectedData}
          mutation={
            !includes(
              [
                PROJECT_CHECKLIST_STATUS.PUBLISHED,
                PROJECT_CHECKLIST_STATUS.ARCHIVED,
              ],
              RFIData?.projectRfi?.status,
            )
              ? deleteStageItem
              : updateProjectRFIPoint
          }
          isDeletable={
            !includes(
              [
                PROJECT_CHECKLIST_STATUS.PUBLISHED,
                PROJECT_CHECKLIST_STATUS.ARCHIVED,
              ],
              RFIData?.projectRfi?.status,
            )
          }
        />
      )}
      {RFIData?.project?.isActive && isEditable && (
        <CanPerform
          action={ALLOWED_ACTION_KEYS.ADD_PROJECT_STAGE_ITEM}
          type={ALLOWED_ACTION_TYPE.BOTH}
        >
          <Portal portalId="stageitem-add-btn">
            <Button
              shape="round"
              type="primary"
              id="add-btn"
              icon={<AddButton />}
              className="add-button"
              disabled={
                RFIData?.status === PROJECT_CHECKLIST_STATUS?.PUBLISHED ||
                RFIData?.status === PROJECT_CHECKLIST_STATUS?.ARCHIVED ||
                !RFIData?.project?.isActive
              }
              onClick={() => {
                handleAddEdit();
              }}
            >
              Add
            </Button>
          </Portal>
        </CanPerform>
      )}
      {isDesktopViewport ? (
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={DraggableBodyRow} sensors={sensors}>
          <SortableContext items={rfiPointListData?.map((i) => i?.id) ?? []} strategy={verticalListSortingStrategy}>
            <Table
              components={{ body: { row: Rows } }}
              columns={columns}
              dataSource={rfiPointListData || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowKey="id"
              loading={loading || reorderLoading}
              rowClassName={(record) => {
                if (!record?.isActive) return 'row-disabled';
              }}
            />
          </SortableContext>
        </DndContext>
      ) : (
        <InfiniteScrollHandler
          scrollFlag={scrollFlag}
          loading={loading || reorderLoading}
          // refetchData={handleRefetch}
          setScrollFlag={setScrollFlag}
          hasMore={hasMore}
          wrapperClassName="stage-item-scroll-wrapper"
          dataLength={rfiPointListData?.length}
          skeletonRows={columns?.length - 3}
        >
          <div id="sortable-container-div" />
          <DndContext collisionDetection={closestCenter} onDragEnd={DraggableBodyRow} sensors={sensors} modifiers={[restrictToVerticalAxis]}>
            <SortableContext items={rfiPointListData} strategy={rectSortingStrategy}>
              {map(rfiPointListData, (item, index) => {
                return (
                  <DraggableCard key={item?.id} className="stage-card">
                    <div className="common-card d-flex">
                      <div className="mr-5 fw-medium">
                        {isEditable && RFIData?.project?.isActive && (
                          <DragHandle />
                        )}
                      </div>
                      <div>
                        <div className="card-header fw-medium">
                          <Row wrap={false}>
                            <Col flex="auto" className="d-flex">
                              <span className="mr-5">{index + 1}.</span>
                              <EllipsisText text={item?.title} />
                            </Col>
                          </Row>
                        </div>
                        <div className="card-content text-secondary">
                          <br />
                          <div className="mb-15">
                            <span className="fw-medium mr-5">Input:</span>
                            {item?.type}
                          </div>
                          <div className="mb-15 d-flex align-center">
                            <span className="fw-medium mr-5">Photos:</span>
                            {item?.photo === true ? (
                              <RightIcon />
                            ) : (
                              <WrongIcon className="wrong-icon" />
                            )}
                          </div>
                          <div className=" d-flex align-center">
                            <span className="fw-medium mr-5">Remarks:</span>
                            {item?.remark === true ? (
                              <RightIcon />
                            ) : (
                              <WrongIcon className="wrong-icon" />
                            )}
                          </div>
                        </div>
                      </div>
                      <span className="d-flex position-absolute stage-item-action-btn">
                        {getActionButtons(item)}
                      </span>
                    </div>
                  </DraggableCard>
                );
              })}
            </SortableContext>
          </DndContext>
        </InfiniteScrollHandler>
      )
      }
    </div >
  );
};

export default RfiPointsTable;
