import React from 'react';
import { useMedia } from 'react-use';
import { LoginLogo } from '../../assets/svg/login';
import { BREAKPOINTS } from '../../common/constants';

const Maintenance = () => {
  const isDesktopViewport = useMedia(`(min-width:${BREAKPOINTS.desktop}px)`);
  return (
    <div className="gx-login-container">
      <div className="login-logo">
        <LoginLogo
          width={isDesktopViewport ? '300' : '200'}
          height={isDesktopViewport ? '80' : '60'}
        />
      </div>
      <div className="maintenance-content">
        <div className="card-body d-flex flex-vertical align-center justify-center">
          <h1 className="main-text">Website is under maintenance</h1>
          <div className="secondary-text text-secondary">
            Sorry for the inconvenience, we'll be back shortly
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
